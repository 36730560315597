import Swiper from 'swiper';

const swipers = [...document.querySelectorAll('.path__swiper')];

swipers.forEach( swiper => {
  const exitBtn = swiper.querySelector('.path__footer__exit');
  if (window.innerWidth < 768) {
    swiper.querySelector('.path__footer__previous').innerText = 'PREV'
  }
  const pathSwiper = new Swiper(swiper, {
    simulateTouch: false,
    hashNavigation: true,
    autoHeight: true,
    speed: 0,
    keyboard: {
      enable: true
    },
    navigation : {
      nextEl: '.path__footer__next',
      prevEl: '.path__footer__previous',
      disabledClass: 'path__footer--disabled'
    },
    on: {
      init: function () {
        // exitBtn.href = this.slides[this.activeIndex].dataset.parent;
      },
      slideChange: function(){
        window.scrollTo(0,0);
        // exitBtn.href = this.slides[this.activeIndex].dataset.parent;
      }
    }
  });  
})