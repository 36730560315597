const imagesLoaded = require('imagesloaded'); 


function pathNav_render(paths) {
  function resetPathNav(){
    $('#pathNavNavigation_Pagination_Next').removeClass('disabled');
    $('#pathNavNavigation_Pagination_Prev').removeClass('disabled');
    $('#pathList ul').children('li').remove();
  }
  resetPathNav();

  let hash = window.location.hash;
  let userInt = parseInt(hash.substring(
    hash.lastIndexOf("?user=") + 6,
    hash.lastIndexOf("?")));

  let stepInt = parseInt(hash.substring(
    hash.lastIndexOf("?step=") + 6));

  let user = paths[userInt][0].pathCurator;
  let pathCount = paths[userInt].length;

 
  $('#pathList ul').append(`<li class="uppercase" id="pathCurator">${user} ${stepInt}/${pathCount}</li>`);

  for (var i = 0; i < paths[userInt].length; i++) {
    let pathItem = `<li><span class="path-arrow"> > </span> <span>${paths[userInt][i].slug}</span></li>`
    if (i == stepInt-1) {
      pathItem = `<li> <span class="path-arrow"> > </span> <span class="active">${paths[userInt][i].slug}</span></li>`
    }
    if (i == 0) { 
      pathItem = `<li><span>${paths[userInt][i].slug}</span></li>`
      if (i == stepInt-1) {
      pathItem = `<li><span class="active">${paths[userInt][i].slug}</span></li>`
      }
 
    }
    $('#pathList ul').append(pathItem);
  }

  $('#pathNav').show();

  function checkPagination(){
    const stepIntIndex = stepInt - 1;
    if (stepIntIndex == 0) {
      $('#pathNavNavigation_Pagination_Prev').addClass('disabled');
    } else {
     $('#pathNavNavigation_Pagination_Prev').attr('href', (paths[userInt][stepIntIndex-1].pathURL)+'#path?user=' + userInt + '?step=' + (stepInt-1));
    }  
    if (stepIntIndex == pathCount-1) {
      $('#pathNavNavigation_Pagination_Next').addClass('disabled');
    } else {
     $('#pathNavNavigation_Pagination_Next').attr('href', (paths[userInt][stepIntIndex+1].pathURL)+'#path?user=' + userInt + '?step=' + (stepInt+1));
    }

  }
  checkPagination();


  comment_render(paths, user, userInt, stepInt);
  // Make comments on the same page work as anchor links.
}



function comment_render(paths, user, userInt, stepInt) {



  $(window).on('resize', function(event) {
    $('figure .row').each(function(index, el) {
      $(this).width($(this).siblings('img').width()).css('opacity', '1');
    });
  });

  imagesLoaded(document.getElementsByTagName('main')[0], function(){
    $(window).trigger('resize');
  });


  function comment_render_User(paths, userInt, stepInt) {
    const path = paths[userInt];


    for (var i = path.length - 1; i >= 0; i--) {

      const isActiveComment = stepInt-1 == i;

      if (path[i].imageBlockComment != "") {
        const pathImageComment_URL = path[i].imageURL;
        $('figure img').each(function(index, el) {
          const figure_URL = $(el).attr('src');
          if (pathImageComment_URL == figure_URL) {

            let commentString = `<div  class="comment col-sm-7 col-xs-12">${path[i].imageBlockComment}</div>`;
            if (isActiveComment) {
              commentString = `<div  class="comment col-sm-7 col-xs-12 active-comment">${path[i].imageBlockComment}</div>`;
            }
            $(el).siblings('.row').append(commentString);
          } 
        });
       }

       if (isActiveComment && path[i].textBlockComment != "") {
        let commentStringTextBlock = `<div class="comment col-sm-7 col-xs-12 active-comment">${path[i].textBlockComment}</div>`;
         $('.project-text .row').append(commentStringTextBlock);
       }

       if (stepInt == path.length && isActiveComment) {
        const pathHome = $('#pathNavURL').attr('href');
        $('.active-comment').after(`<div class="comment col-sm-12 text-center comment-end"><a href="${pathHome}">*End of ${path[i].pathCurator.split(" ")[0]}'s Path*</a></div>`);
       }

      if (isActiveComment) {
        document.querySelector('.active-comment').scrollIntoView({ 
          // behavior: 'smooth' 
          block: 'start'
        });
        const block = document.getElementsByClassName('active-comment')[0];
        const windowHeight = window.innerHeight;
        if ((block.offsetTop + windowHeight) < document.documentElement.scrollHeight) {
          window.scrollBy(0, -$(window).height()*0.5);
        }
      }

      $('main').css('padding-bottom', $('#pathNav').outerHeight() + 16);

    }
    comment_render_OtherUsers(paths, userInt);
  } 

  function comment_render_OtherUsers(paths, userInt) {
    // Cycle through all PATH USERS objects.
    Object.keys(paths).forEach(function(key, index) {
      const path = paths[key];
      // If current path user then ignore.
      if (key != userInt) {
        // Cycle through PATH USER COMMENTS
        for (var i = path.length - 1; i >= 0; i--) {

          const pathImageComment_URL = path[i].imageURL;
         
          $('figure img').each(function(index, el) { // Cycle through all images on page.
            const figure_URL = $(el).attr('src');
            // If a comment is attached to the image then add other comments
            if (pathImageComment_URL == figure_URL) {
              const otherComentsElement = `<div class="other-comments col-sm-5 col-xs-12"><ul><h3 class="">Also annotated by:</h3></ul></div>`;
              if (!$(this).data('isOtherCommentsOnFigure')) {
                const isEndOfPath = $(this).parent().find('.row').find('.comment-end').length;
                $(this).data('isOtherCommentsOnFigure', true);
             
                if (isEndOfPath) {
                  $('.comment-end').before(otherComentsElement);
                } else {
                  $(this).siblings('.row').append(otherComentsElement);
                }
              }
              const otherCommentString = `<li class="other-comment uppercase"><a href='${path[i].pathURL}#path?user=${ parseInt(key) }?step=${parseInt(i) + 1}'>${path[i].pathCurator}</a></li>`;
              $(this).parent().find('.other-comments ul').append(otherCommentString);
            }

          });

          if (path[i].textBlockComment != "" && window.location.href.split(window.location.hash)[0] == path[i].pathURL ) {
            const otherCommentsTextBlock = `<div class="other-comments col-sm-5 col-xs-12"><ul><h3 class="uppercase">Also annotated by:</h3></ul></div>`;
            var $this = $('.project-text');
            if (!$this.data('isOtherCommentsOnText')) {
              $this.find('.comment:first-of-type').after(otherCommentsTextBlock);
              $this.data('isOtherCommentsOnText', true);
            }
            const otherCommentsTextBlockString = `<li class="other-comment"><a href='${path[i].pathURL}#path?user=${ parseInt(key) }?step=${parseInt(i) + 1}'>${path[i].pathCurator}</a></li>`;
            $this.find('.other-comments ul').append(otherCommentsTextBlockString);
          }

        }
      }
    });
  }

  comment_render_User(paths, userInt, stepInt);
  

}

function fetchPaths() {
  const api = document.getElementById('pathNav').getAttribute('data-api');
  fetch(api)
    .then(function(response) {
      return response.json();
    })
    .then(function(paths) {
      pathNav_render(paths);
    });
}




function isPathMode() {
  var hash = window.location.hash;
  if (hash.substring(1, 5) == "path") {
    return true
  } else {
    return false
  }

}

function init() {
  if (isPathMode()) {
    $()
    $('body').addClass('path_mode');
    fetchPaths();
  } else { 
    $('#pathNav').hide();
     $('body').removeClass('path_mode');
  }

  function refreshPaths() {
    $('figure img').each(function(index, el) {
      $(this).data('isOtherCommentsOnFigure', false);
    });
    $('.project-text').each(function(index, el) {
      $(this).data('isOtherCommentsOnText', false);
    });
    $('.comment').each(function(index, el) {
      $(el).remove();
    });
    $('.other-comments').each(function(index, el) {
      $(el).remove();
    });
    fetchPaths();
  }

   if (isPathMode()) {
    window.onhashchange = refreshPaths;
   }

}

const path__init = {
  init: () => {
    init();
  }
}

module.exports = path__init;