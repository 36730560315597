// Packages
const $ = require('jquery');
window.$ = window.JQuery =  $;

import './modules/images';
import './modules/path';


const _path = require('./modules/path__init');

$('a').each(function () {
	var a = new RegExp('/' + window.location.host + '/');
	if (!a.test(this.href)) {
		$(this).click(function (event) {
			event.preventDefault();
			event.stopPropagation();
			window.open(this.href, '_blank');
		});
	}
});