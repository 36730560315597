import _ from 'underscore';
import imagesLoaded from 'imagesloaded';
import mediumZoom from 'medium-zoom';

let images = [...document.querySelectorAll('.lazy-image')];

const options = {
  root: document.querySelector('.Page'),
  rootMargin: '0px 0px 200px 0px'
}

function onIntersection(imageEntities) {
  imageEntities.forEach( image => {
    if (image.isIntersecting) {
      observer.unobserve(image.target)
      
      window.innerWidth < 768
        ? image.target.src = image.target.dataset.srcMobile
        : image.target.src = image.target.dataset.src

      imagesLoaded(image.target.parentNode, () => {
        image.target.parentNode.style.paddingBottom = Math.floor(image.target.offsetHeight) + 'px';
        image.target.style.opacity = '1';
        image.target.parentNode.style.background = 'transparent'
      });
    }
  })
}

let observer = new IntersectionObserver(onIntersection, options);

images.forEach( image => observer.observe(image) );

const resizeImage = image => {
  image.closest('figure').style.paddingBottom = image.offsetHeight + 'px';
}
const resizeImages = () => {
  images.forEach(image => {
    resizeImage(image);
  })
}

const throttled = _.throttle(resizeImages, 100);
window.onresize = throttled;

let backgroundColour;
document.body.classList.contains('template_path')
  ? backgroundColour = '#ffaf0f'
  : backgroundColour = '#ffffff'

imagesLoaded(document.querySelector('main'), resizeImages);
mediumZoom('[data-zoomable]', {
  margin: 24,
  background: backgroundColour,
  scrollOffset: 0,
})